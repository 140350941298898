<template>
  <!-- 师傅详情 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra" v-if="info">
        <a-button> 离编/在编 </a-button>
        <a-button @click="onTranslate" :disabled="info.level === 2">
          转带训师傅
        </a-button>
        <a-button> 实时排班查询 </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatar" />
            </div>
            <div class="name">{{ info.realName }}</div>
            <a-tag color="green" v-if="info.status === 1">
              师傅状态：{{ statuText[info.status] }}
            </a-tag>
            <a-tag v-else>师傅状态：{{ statuText[info.status] }}</a-tag>

            <a-tag color="green" style="margin-top: 5px">{{
              info.communityName
            }}</a-tag>
          </div>
        </div>

        <div class="body-right">
          <div class="text-content">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">师傅信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">师傅姓名：</span>
                  <span>{{ info.realName }}</span>
                </div>
                <div class="li">
                  <span class="label">师傅工号：</span>
                  <span>{{ info.jobNumber }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">师傅类型：</span>
                  <span>{{ levels[info.level] }}</span>
                </div>
                <div class="li">
                  <span class="label">师傅联系方式：</span>
                  <span>{{ info.mobile }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li address">
                  <span class="label">常住地址：</span>
                  <span>{{ info.address }}</span>
                </div>
              </div>
            </div>

            <a-divider />

            <div class="content-sub-title">
              <span>业务信息</span>
              <a-button
                v-if="info.rateType == 1"
                type="link"
                @click="onStarChangeOpen"
              >
                编辑星级
              </a-button>
              <a-button
                v-else-if="info.rateType == 2"
                type="link"
                @click="getSetServicerRate"
              >
                编辑私人比例
              </a-button>
              <div v-else>
                <a-select
                  style="width: 140px; margin-left: 15px"
                  placeholder="设置比例模式"
                  @change="handleSelectChange"
                  v-if="showt"
                >
                  <a-select-option
                    v-for="(item, index) in list"
                    :value="item.name"
                    :key="index"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>
            </div>

            <div class="content-item content-item-flex">
              <div class="rate">
                <span>师傅星级：</span>
                <a-rate v-model="info.bclevel" disabled />
              </div>
              <div class="area">
                <span>接单地区：</span>
                <span>{{ info.orderAddress }}</span>
              </div>
            </div>
          </div>

          <div class="text-content text-content-2" v-if="info.lector">
            <div class="content-item">
              <div class="content-item-header content-item-header-flex">
                <div class="title">培训信息</div>
                <a-button> 更多培训明细 </a-button>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">理论讲师姓名：</span>
                  <span>{{ info.lector }}</span>
                </div>
                <div class="li">
                  <span class="label">实操讲师姓名：</span>
                  <span>{{ info.traing }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">带训师傅姓名：</span>
                  <span>{{ info.visitiser }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="id-card-content">
            <div class="content-sub-title">
              <span>实名信息</span>
              <a-button type="link"> 更新 </a-button>
            </div>
            <div class="content-item content-item-flex id-card">
              <div class="id-card-img">
                <div class="item">
                  <img class="img" :src="info.IDcardFrontUrl" />
                  <div class="text">身份证国徽面</div>
                </div>
                <div class="item item-2">
                  <img class="img" :src="info.IDcardBackUrl" />
                  <div class="text">身份证人像面</div>
                </div>
              </div>

              <a-divider class="divider-vertical" type="vertical" />

              <div class="id-card-text">
                <div class="text text-1">身份证号码：{{ info.IDcardNum }}</div>
                <div class="text">有效期：{{ info.IDcardEndTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="modalVisible"
      title="编辑星级"
      @cancel="handleModalCancel"
    >
      <div class="modal-info" v-if="info">
        <div class="left">
          <span class="label">师傅姓名：</span>
          <span class="value">{{ info.realName }}</span>
        </div>
        <div class="right">
          <span class="label">当前星级：</span>
          <a-rate :default-value="info.bclevel" disabled />
          <span class="value">{{ info.bclevel }}星级</span>
        </div>
      </div>
      <div class="modal-edit">
        <span class="label">变更星级：</span>
        <a-select placeholder="选择星级" v-model="bclevel">
          <a-select-option
            v-for="item in starData"
            :key="item.id"
            :value="item.level"
          >
            <a-rate disabled v-model="item.level" />
            <span style="marginleft: 12px" v-if="item.level === 1">实习期</span>
            <span style="marginleft: 12px" v-else>{{ item.level }}星</span>
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button @click="handleModalCancel" :disabled="modalLoading">
          取消
        </a-button>
        <a-button
          type="primary"
          :loading="modalLoading"
          @click="handleModalConfirm"
        >
          保存
        </a-button>
      </template>
    </a-modal>
    <!-- 私人比例 -->
    <a-modal v-model="overseer" title="私人比例模式" @cancel="handleModalCan">
      <a-form-model
        ref="ruleForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :model="form"
        :rules="rules"
      >
        <a-form-model-item ref="name" label="比例" prop="rate">
          <a-input placeholder="请输入0%到70%的比例" v-model="form.rate" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCan"> 取消 </a-button>
        <a-button type="primary" @click="hl"> 保存 </a-button>
      </template>
    </a-modal>
    <!--  -->
  </div>
</template>
<script>
let masterId = ''
export default {
  data() {
    return {
      title: '',
      info: null,
      statuText: {
        1: '在编',
        0: '离编'
      },
      levels: {
        1: '服务师傅',
        2: '带训师傅'
      },
      list: [
        //  {
        //   id: 0,
        //   icon: ' ',
        //   name: '设置比例模式'
        // },
        {
          id: 1,
          icon: ' ',
          name: '星级比例模式'
        },
        {
          id: 2,
          icon: ' ',
          name: '私人比例模式'
        }
      ],
      modalVisible: false,
      modalLoading: false,
      bclevel: undefined,
      starData: [],
      overseer: false,
      showt: true,
      form: {
        id: '',
        rate: ''
      },
      rules: {
        rate: [{ required: true, message: '请输入比例', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    masterId = this.$route.query.id
    this.form.id = masterId
    this.getMasterInfo()
  },
  methods: {
    getMasterInfo() {
      const data = {
        id: masterId
      }
      this.$axios.getMasterInfo(data).then((res) => {
        this.info = res.data.data
      })
    },
    handleSelectChange(value) {
      console.log('value', value)
      if (value == '星级比例模式') {
        this.onStarChangeOpen()
      } else {
        this.getSetServicerRate()
      }
    },
    onStarChangeOpen() {
      // 编辑星级
      this.getStarData()
      this.modalVisible = true
    },
    handleModalCancel() {
      // 取消编辑星级
      this.modalLoading = false
      this.modalVisible = false
      this.bclevel = undefined
      this.showt = false
      this.$nextTick(function () {
        this.showt = true
      })
    },
    getSetServicerRate() {
      this.overseer = true
    },
    handleModalCan() {
      this.overseer = false
      this.form.rate = ''
      this.showt = false
      this.$nextTick(function () {
        this.showt = true
      })
    },
    hl() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          if (this.form.rate <= 70) {
            data.rate = this.form.rate / 100
          } else {
            this.$message.error('比例不能超出70')
            return
          }

          this.$axios.getSetServicerRate(data).then((res) => {
            console.log(res)
            this.$message.success('操作成功')
            this.overseer = false
            this.form.rate = ''
          })
        }
      })
    },
    handleModalConfirm() {
      // 编辑星级
      if (!this.bclevel) {
        this.$message.error('请先选择星级')
        return
      }
      this.modalLoading = true
      const data = {
        id: masterId,
        level: this.bclevel
      }
      this.$axios
        .changeMasterRate(data)
        .then(() => {
          this.$message.success('操作成功')
          this.info.bclevel = this.bclevel
          this.handleModalCancel()
        })
        .catch(() => {
          this.handleModalCancel()
        })
    },
    getStarData() {
      // 获取师傅星级源数据
      const data = {
        userType: 'servicer'
      }
      this.$axios.getStarData(data).then((res) => {
        this.starData = res.data.data
      })
    },
    onTranslate() {
      this.$confirm({
        title: '确定转培训师傅？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: masterId
          }
          this.$axios.masterTranslate(data).then(() => {
            this.$message.success('操作成功')
            this.getMasterInfo()
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  height: 100%;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
  margin-left: 24px;
}

.text-content-2 {
  margin-top: 16px;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}

.ul .address {
  width: auto;
}

.area {
  margin-left: 56px;
}

.id-card {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
}

.id-card-img {
  display: flex;
}

.id-card-img .item {
  width: 160px;
  height: 90px;
}

.id-card-img .item-2 {
  margin-left: 24px;
}

.id-card-img .text {
  text-align: center;
  line-height: 22px;
  margin-top: 8px;
}

.divider-vertical {
  height: 100px;
  margin: 10px 32px 10px 32px;
}

.id-card-text .text-1 {
  margin: 30px 0 16px 0;
}

.modal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-info .left .label {
  color: rgba(0, 0, 0, 0.85);
}

.modal-info .left .value,
.modal-info .right .value {
  color: rgba(0, 0, 0, 0.65);
}

.modal-info .right .label {
  color: #252525;
}

.modal-info .right .value,
.modal-edit .value {
  margin-left: 24px;
}

.modal-edit {
  padding-top: 24px;
}

.modal-edit .ant-select {
  width: 240px;
}

.modal-edit >>> .star-value {
  margin-left: 12px;
}
</style>
